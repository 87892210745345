:root {
  --font-family-base: 'Helvetica Neue Medium', sans-serif;
  --font-family-alt: 'Helvetica Rounded Bold', sans-serif;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  --font-size-10: 10px;
  --font-size-12: 12px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-25: 25px;
  --font-size-30: 30px;
  --font-size-35: 35px;
  --font-size-40: 40px;
  --font-size-50: 50px;
  --font-size-60: 60px;
  --font-size-100: 100px;
  --font-size-unit: 1px;
  --line-height-text: 1.4;
  --line-height-heading: 1.2;
  --font-size-viewport-min: 360;
  --font-size-viewport-max: 1300;

  --font-size-growth: calc(1 / (var(--font-size-viewport-max) - var(--font-size-viewport-min)));
  --m: calc((var(--font-size-growth) * 100vw) - (var(--font-size-growth) * var(--font-size-viewport-min) * var(--font-size-unit)));

  --font-size-18-22: clamp(18px, calc(18px + 4 * var(--m)), 22px);
  --font-size-20-24: clamp(20px, calc(20px + 4 * var(--m)), 24px);
  --font-size-25-35: clamp(25px, calc(25px + 10 * var(--m)), 35px);
  --font-size-30-40: clamp(30px, calc(30px + 10 * var(--m)), 40px);
  --font-size-30-50: clamp(30px, calc(30px + 20 * var(--m)), 50px);
  --font-size-50-100: clamp(50px, calc(50px + 50 * var(--m)), 100px);

  --font-weight-regular: 400;
  --font-weight-bold: 700;
}
