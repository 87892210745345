:root {
  --color-blue: #024d92;
  --color-blue-contrast: #fff;
  --color-yellow: #fdc800;
  --color-yellow-contrast: #000;
  --color-orange: #ea5b0c;
  --color-orange-contrast: #fff;
  --color-green: #286f2b;
  --color-green-contrast: #fff;
  --color-black: #000;
  --color-black-contrast: #fff;
  --color-white: #fff;
  --color-white-contrast: #000;
}

:export {
  --color-blue: #024d92;
  --color-blue-contrast: #fff;
  --color-yellow: #fdc800;
  --color-yellow-contrast: #000;
  --color-orange: #ea5b0c;
  --color-orange-contrast: #fff;
  --color-green: #286f2b;
  --color-green-contrast: #fff;
  --color-black: #000;
  --color-black-contrast: #fff;
  --color-white: #fff;
  --color-white-contrast: #000;
}
