:root {
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-18: 18px;
  --size-20: 20px;
  --size-24: 24px;
  --size-30: 30px;
  --size-35: 35px;
  --size-40: 40px;
  --size-48: 48px;
  --size-60: 60px;
  --size-70: 70px;
  --size-80: 80px;
  --size-90: 90px;
  --size-100: 100px;
  --size-120: 120px;
}
